import { Box, Button, Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { t } from "../features/i18n";
import useStyles from "../configs/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ListRemarks } from "../apiRequest/remark";
import { formatDateAndTime } from "../features/GlobalFunction/globalFunction";
import DeleteModal from "./modals/DeleteModal";
import RemarkModal from "./modals/RemarkModal";
import { useSelector } from "react-redux";
import { RootState } from "../features/redux/store";

let color: string = "#44bcac";

const titleContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};
interface IRemarkCard {
  remarkData: IRemarkData;
  reset: () => void;

};


const RemarkCard: React.FC<IRemarkCard> = ({ remarkData, reset }) => {
  const [openUpdateRemarkModal, setOpenUpdateRemarkModal] = useState(false);
  const [openDeleteRemarkModal, setOpenDeleteRemarkModal] = useState(false);
  const userCode = useSelector((state: RootState) => state.user.userCode);
  const onEdit = () => {
    setOpenUpdateRemarkModal(true)
  };
  const onDelete = () => {
    setOpenDeleteRemarkModal(true)
  };
  return (
    <>
      <Card variant="outlined" sx={{ marginBottom: 2, boxShadow: 1 }}>
        <CardContent>
          <div style={titleContainerStyle}>
            <Typography variant="h6" component="h2" gutterBottom>
              {remarkData.title}
            </Typography>
            {userCode === "superadmin" ? (
              <div>
                <IconButton onClick={onEdit} size="small">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={onDelete} size="small">
                  <DeleteIcon />
                </IconButton>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            {remarkData.updated_at ? (<>{formatDateAndTime(remarkData.updated_at).date}  {formatDateAndTime(remarkData.updated_at).time}</>) : (<></>)}

          </Typography>
          <Typography variant="body2">
            {remarkData.content}
          </Typography>
        </CardContent>
      </Card>
      <RemarkModal
        open={openUpdateRemarkModal}
        onClose={() => setOpenUpdateRemarkModal(false)}
        initialData={
          {
            id: remarkData.id,
            title: remarkData.title,
            content: remarkData.content
          }}
        resetHandler={reset} />
      <DeleteModal
        deleteMessage={t('remark_modal_delete') + "\"" + remarkData.title + "\""}
        open={openDeleteRemarkModal}
        successMessage={t('remark_modal_delete_succesfull')}
        errorMessage={t('remark_modal_delete_error')}
        type={"remark"}
        code={remarkData.id.toString()}
        reset={reset}
        onClose={() => setOpenDeleteRemarkModal(false)} />
    </>
  );
};

const AdminRemark: React.FC = () => {
  const classes = useStyles();
  const apiCalled = useRef(false);
  const [remarkData, setRemarkData] = useState<IRemarkData[]>();
  const [openCreateRemarkModal, setOpenCreateRemarkModal] = useState(false);

  const userCode = useSelector((state: RootState) => state.user.userCode);

  async function loadData(): Promise<void> {
    let remarkData: IRemarkData[] = await ListRemarks();
    setRemarkData(remarkData);
  }

  useEffect(() => {
    if (!apiCalled.current) {
      loadData();
      apiCalled.current = true;
    }
  }, []);

  return (
    <Card
      className={classes.tableCard}
      elevation={3}
      style={{ borderTop: "8px solid" + color }}
    >
      <Box className={classes.tableCardContent}>
        <CardContent
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "0px",
          }}
        >
          <Typography
            className={classes.tableTitle}
            sx={{
              color: color,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item sx={{ mr: "20px" }}>{t("remark_dialog_title")}</Grid>
            </Grid>
          </Typography>
        </CardContent>


        {userCode == "superadmin" ? (
          <Button
            variant="outlined"
            className={classes.tableButton}
            sx={{
              color: color,
              borderColor: color,
              "&:hover": {
                color: "white",
                borderColor: color,
                backgroundColor: color,
              },
            }}
            onClick={() => setOpenCreateRemarkModal(true)}
          >
            {t("remark_dialog_add")}
          </Button>

        ) : (
          <div></div>
        )}
      </Box>
      <Box>
        {remarkData
          ?.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
          .map((remark) => (
            <RemarkCard
              key={remark.id}
              remarkData={remark}
              reset={() => loadData()}
            />
          ))}
      </Box>
      <RemarkModal
        open={openCreateRemarkModal}
        onClose={() => setOpenCreateRemarkModal(false)}
        resetHandler={() => loadData()} />
    </Card>
  );
};

export default AdminRemark;
